.player {
  width: 150px;
  height: 75px;
  border: 1px solid #aaa;
  padding: 10px;
  margin: 10px;
  float: left;
  text-align: center;
  border-radius: 5px;
}

.players {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.player-drinks {
  font-weight: 400;
}
