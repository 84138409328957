body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('/bg.png');
  background-repeat: repeat repeat;
}

body, html, #root  {
  min-height: 100%;
  margin: 0;
  padding:0;
  font-family: 'Cinzel Decorative', cursive;
  font-weight: 700;
  font-size: 24px;
}

button, input[type=submit] {
  font-family: 'Cinzel Decorative', cursive;
  font-weight: 700;
  font-size: 32px;
  border: 2px solid black;
  padding: 10px;
  cursor: pointer;
  background-color: white;
  outline: none;
}

button:hover, input[type=submit]:hover {
  color: white;
  background-color: black;
}


button:active, input[type=submit]:active {
  outline: none;
  color: black;
  background-color: white;
}

.drink-counter {
  text-align: center;
}

.actions {
  text-align: center;
  margin-top: 50px;
}

.actions button {
  margin-bottom: 20px;
}

.small-button {
  font-size: 24px;
  padding: 5px;
  margin-top: 30px;
}

h1, h2, h3 {
  text-align: center;
  margin-top: 75px;
}

input[type=text] {
  height: 40px;
  width: 300px;
  font-size: 32px;
}
